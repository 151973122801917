import request from '../../../api/request';
import { AcademicLevelListItem } from '../../../api/requests/schools';
import {
  CourseMaintainerListResponse,
  CourseRequest,
  CreateCourseBody,
  UpdateCourseBody,
  UpdateCourseSyllabusBody,
} from '../../../types/courseMaintainerTypes';
import { Course } from '../../../types/parametersTypes';
import { SchoolType } from '../../../types/sectionMaintainerTypes';
import { CoursesFeaturesOptions } from '../../Configurations/academics/interfaces/courses-features.interface';

export const COURSE = {
  GET_COURSES:
    '/maintainers-portal/academic-offer/curricular-subjects/search-course',
  FORM_DATA: '/maintainers-portal/academic-offer/curricular-subjects/form-data',
  COURSES: '/maintainers-portal/academic-offer/curricular-subjects',
  CREATE_COURSE: '/maintainers-portal/academic-offer/curricular-subjects',
  GET_COURSE: (courseId: number) =>
    `/maintainers-portal/academic-offer/curricular-subjects/${courseId}`,
  UPDATE_COURSE: (courseId: number) =>
    `/maintainers-portal/academic-offer/curricular-subjects/${courseId}`,
  UPDATE_COURSE_SYLLABUS: (courseId: number) =>
    `/maintainers-portal/academic-offer/curricular-subjects/${courseId}/syllabus`,
  COURSE_TYPES:
    '/maintainers-portal/academic-offer/curricular-subjects/type/all',
};

/**
 * Obtiene listado de todas las asignaturas con su facultad que concuerdan con la búsqueda
 * @param searchText
 * @returns
 */
export const getCoursesRequest = (searchText: string) => {
  return request<Course[]>(COURSE.GET_COURSES, {
    params: { searchText },
  });
};

/**
 * Obtiene el listado de todas las facultades para llenar el selector del filtro en el mantenedor de secciones
 * @returns
 */
export const getSchoolListRequest = () => {
  return request<{
    schools: SchoolType[];
    activities: {
      id: number;
      name: string;
      createdAt: Date;
      updatedAt: Date;
    }[];
    academicLevels: AcademicLevelListItem[];
    modalities: { id: number; name: string }[];
    coursesFeatures?: CoursesFeaturesOptions | null;
  }>(COURSE.FORM_DATA);
};

/**
 * Obtiene listado de las asignaturas
 */
export const requestCoursesMaintainerList = (data: {
  items_per_page: number;
  page: number;
  schoolId?: string | number;
  search_text?: string | null;
}) => {
  return request<CourseMaintainerListResponse>(`${COURSE.COURSES}`, {
    params: data,
  });
};

export const requestCourseTypes = () => {
  return request<{ data: { id: number; name: string; priority: number }[] }>(
    `${COURSE.COURSE_TYPES}`,
  );
};

export const requestCreateCourse = (data: CreateCourseBody) => {
  return request<any>(COURSE.CREATE_COURSE, {
    method: 'POST',
    data,
  });
};

export const requestGetCourse = (courseId: number) => {
  return request<{ data: CourseRequest }>(`${COURSE.GET_COURSE(courseId)}`);
};

export const requestUpdateCourse = (data: UpdateCourseBody) => {
  return request<any>(COURSE.UPDATE_COURSE(data.id), {
    method: 'put',
    data,
  });
};

export async function updateCourseSyllabus({
  id,
  ...data
}: UpdateCourseSyllabusBody) {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]: [string, any]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else if (value) {
      formData.append(key, value);
    }
  });
  return request<void>(COURSE.UPDATE_COURSE_SYLLABUS(id), {
    method: 'POST',
    data: formData,
  });
}

export async function removeCourseSyllabus(id: UpdateCourseSyllabusBody['id']) {
  return request<void>(COURSE.UPDATE_COURSE_SYLLABUS(id), {
    method: 'POST',
  });
}
