import { addToast, Button, SelectOptionType } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { AxiosResultDefaultError } from '../../../../api/request';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import { PathsLayouts } from '../../../../config/routes';
import {
  ActivityType,
  Compatibility,
  CourseType,
  CreateCourseBody,
  Prerequisite,
  UpdateCourseBody,
} from '../../../../types/courseMaintainerTypes';
import { CoursesFeaturesOptions } from '../../../Configurations/academics/interfaces/courses-features.interface';
import {
  removeCourseSyllabus,
  requestCreateCourse,
  requestUpdateCourse,
  updateCourseSyllabus,
} from '../api';
import CourseFormPart1 from './CourseFormPart1';
import CourseFormPart2 from './CourseFormPart2';

export type CourseFormFields = {
  shortening: string;
  code: string;
  name: string;
  credits: string;
  school: SelectOptionType;
  academicLevel: SelectOptionType;
  modality: SelectOptionType;
  retakeExamAllowed: SelectOptionType;
  courseTypes: number[];
  attendance: { minPercentage: number; activityTypeId: number }[];
  prerequisites: Prerequisite[];
  compatibilities: Compatibility[];
  syllabusFile: File | null;
};

type CourseFormProps = {
  mode: CourseFormMode;
  defaultValues: DefaultValues<CourseFormFields>;
  schools: SelectOptionType[];
  courseTypes: CourseType[];
  activityTypes: ActivityType[];
  academicLevels: SelectOptionType[];
  modalities: SelectOptionType[];
  retakeExamOptions: SelectOptionType[];
  coursesFeatures?: CoursesFeaturesOptions;
  isDisabled: boolean;
  onDownloadSyllabus?: () => void;
};

export enum CourseFormMode {
  WATCH = 'WATCH',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
}

export enum RetakeExamAllowedCode {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

const CourseForm = ({
  mode,
  defaultValues,
  schools,
  courseTypes,
  activityTypes,
  academicLevels,
  modalities,
  retakeExamOptions,
  coursesFeatures,
  isDisabled,
  onDownloadSyllabus,
}: CourseFormProps) => {
  const prefix = 'courses.update';
  const { t } = useTranslation();
  const history = useHistory();
  const { id: courseId } = useParams<{ id: string }>();
  const methods = useForm<CourseFormFields>({
    defaultValues: { ...defaultValues },
  });
  const { handleSubmit } = methods;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const goBack = useCallback(() => {
    history.push(`${PathsLayouts.academicOffers}/curricular-subjects`);
  }, [history]);

  const responseCreateOrUpdate = useCallback(
    (error: false | AxiosResultDefaultError | undefined) => {
      if (error) {
        if (
          error?.data?.message?.some((err: string) => err === 'SHORT_IN_USE')
        ) {
          addToast({
            icon: 'warning',
            color: 'warning',
            text: t(`${prefix}.validateShortening`),
          });
        } else if (
          error?.data?.message?.some((err: string) => err === 'COD_IN_USE')
        ) {
          addToast({
            icon: 'warning',
            color: 'warning',
            text: t(`${prefix}.validateCode`),
          });
        } else {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t('common.errors.save'),
          });
        }
        setIsConfirmed(false);
      } else {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(
            `common.messages.${
              mode === CourseFormMode.CREATE ? 'saveSuccess' : 'editSuccess'
            }`,
          ),
        });
        goBack();
      }
    },
    [goBack, mode, t],
  );

  const onUpdateSyllabus = useCallback(
    async (courseId: string, values: CourseFormFields) => {
      if (values?.syllabusFile) {
        if (values?.syllabusFile?.lastModified !== -2) {
          await updateCourseSyllabus({
            id: +courseId,
            file: values?.syllabusFile,
          });
        }
      } else {
        await removeCourseSyllabus(+courseId);
      }
    },
    [],
  );

  const onUpdate = useCallback(
    async (courseId: string, isDisabled: boolean, values: CourseFormFields) => {
      let payload: UpdateCourseBody = {
        id: +courseId,
        types: values.courseTypes ?? [],
        assistanceRequirements: values?.attendance ?? [],
        dependsOn:
          values?.prerequisites?.map((prerequisite) => prerequisite.id) ?? [],
        compatibilityIds:
          values?.compatibilities?.map((compatibility) => compatibility.id) ??
          [],
      };
      if (!isDisabled) {
        payload = {
          id: +courseId,
          name: values.name,
          code: values.code,
          shortening: values.shortening,
          credits: +values.credits,
          schoolId: +values.school.value,
          academicLevelId: +values.academicLevel.value,
          modalityId: values.modality?.value
            ? Number(values.modality?.value)
            : null,
          retakeExamAllowed:
            values.retakeExamAllowed.value === RetakeExamAllowedCode.Enabled,
          types: values.courseTypes ?? [],
          assistanceRequirements: values?.attendance ?? [],
          dependsOn:
            values?.prerequisites?.map((prerequisite) => prerequisite.id) ?? [],
          compatibilityIds:
            values?.compatibilities?.map((compatibility) => compatibility.id) ??
            [],
        };
      }
      const response = await requestUpdateCourse(payload);
      return response;
    },
    [],
  );

  const onCreate = useCallback(async (values: CourseFormFields) => {
    const payload: CreateCourseBody = {
      name: values.name,
      code: values.code,
      shortening: values.shortening,
      credits: +values.credits,
      types: values?.courseTypes ?? [],
      schoolId: +values.school.value,
      academicLevelId: +values.academicLevel.value,
      assistanceRequirements: values?.attendance ?? [],
      dependsOn:
        values?.prerequisites?.map((prerequisite) => prerequisite.id) ?? [],
      compatibilityIds:
        values?.compatibilities?.map((compatibility) => compatibility.id) ?? [],
    };
    const response = await requestCreateCourse(payload);
    return response;
  }, []);

  const onSubmit = useCallback(
    async (values: CourseFormFields) => {
      if (!values.courseTypes || values.courseTypes.length === 0) {
        addToast({
          icon: 'warning',
          color: 'warning',
          text: t(`${prefix}.validateCourseType`),
        });
        return;
      }

      if (
        (!values.prerequisites || values.prerequisites.length === 0) &&
        !isConfirmed
      ) {
        setIsOpen(true);
        return;
      }

      const { error, data } = courseId
        ? await onUpdate(courseId, isDisabled, values)
        : await onCreate(values);

      if (!error && data?.id) {
        await onUpdateSyllabus(`${data?.id}`, values);
      }

      responseCreateOrUpdate(error);
    },
    [
      t,
      isConfirmed,
      courseId,
      onUpdateSyllabus,
      responseCreateOrUpdate,
      onUpdate,
      onCreate,
      isDisabled,
    ],
  );

  const onConfirm = () => {
    setIsConfirmed(true);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isConfirmed) {
      handleSubmit(onSubmit)();
    }
  }, [isConfirmed, handleSubmit, onSubmit]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CourseFormPart1
            schools={schools}
            courseTypes={courseTypes}
            academicLevels={academicLevels}
            modalities={modalities}
            retakeExamOptions={retakeExamOptions}
            coursesFeatures={coursesFeatures}
            mode={mode}
            isDisabled={isDisabled}
          />
          <CourseFormPart2
            activityTypes={activityTypes}
            mode={mode}
            isDisabled={isDisabled}
            onDownloadSyllabus={onDownloadSyllabus}
          />
          {mode !== CourseFormMode.WATCH && (
            <Row className="py-5 justify-content-end pt-5">
              <Col xs={12} lg={3} className="pb-2 order-2 order-lg-1">
                <Button
                  type="button"
                  outlined
                  onClick={goBack}
                  text={t(`common.actions.cancel`)}
                  disabled={methods.formState.isSubmitting}
                  fullwidth
                />
              </Col>
              <Col xs={12} lg={3} className="pb-2 order-1 order-lg-2">
                <Button
                  type="submit"
                  text={
                    courseId
                      ? t(`${prefix}.updateCourse`)
                      : t(`${prefix}.createCourse`)
                  }
                  loading={methods.formState.isSubmitting}
                  fullwidth
                />
              </Col>
            </Row>
          )}
        </Form>
      </FormProvider>

      <ConfirmationModal
        iconName="warning"
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        title={t(`${prefix}.confirmModalTitle`)}
        body={t(`${prefix}.confirmModalBody`)}
        primaryBtn={{
          text: t(`common.actions.confirm`),
          action: onConfirm,
        }}
        secondaryBtn={{
          text: t(`common.actions.cancel`),
          action: () => setIsOpen(false),
        }}
      />
    </>
  );
};

export default CourseForm;
