import { Button, Table, FileInputControlled } from '@octano/global-ui';
import { useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row } from 'reactstrap';

import { SectionTitle } from '../../../../components/text';
import TableEmptyContent from '../../../../components/text/TableEmptyContent';
import { ActivityType } from '../../../../types/courseMaintainerTypes';
import { CourseFormFields, CourseFormMode } from './CourseForm';
import ModalAddCompatibility from './ModalAddCompatibility';
import ModalAddPrerequisite from './ModalAddPrerequisite';
import { useColumnsCompatibilities } from './useColumnsCompatibilities';
import { useColumnsPrerequisites } from './useColumnsPrerequisites';

type CourseFormPart2Props = {
  mode: CourseFormMode;
  isDisabled: boolean;
  activityTypes: ActivityType[];
  onDownloadSyllabus?: () => void;
};

const CourseFormPart2 = ({
  mode,
  activityTypes,
  onDownloadSyllabus,
}: CourseFormPart2Props) => {
  const prefix = 'courses.update';
  const { t } = useTranslation();
  const { control, watch, getValues, setValue } =
    useFormContext<CourseFormFields>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const columnsPrerequisites = useColumnsPrerequisites({ mode });
  const [modalCompatibilitiesOpen, setModalCompatibilitiesOpen] =
    useState<boolean>(false);
  const columnsCompatibilities = useColumnsCompatibilities({ mode });

  const prerequisites = watch('prerequisites') || [];
  const attendance = watch('attendance') || [];
  const compatibilities = watch('compatibilities') || [];
  const syllabusFileInput = watch('syllabusFile') || null;

  const canDownloadSyllabus = useMemo(
    () => syllabusFileInput?.lastModified === -2,
    [syllabusFileInput?.lastModified],
  );

  const onChangeAttendance = (attendanceId: number, value: string) => {
    if (isNaN(+value)) return;
    if (+value < 0 || +value > 100) return;
    const newAttendance = getValues('attendance') ?? [];
    const index = newAttendance.findIndex(
      (att) => att.activityTypeId === attendanceId,
    );
    if (index >= 0) {
      newAttendance[index].minPercentage = +value;
    } else {
      newAttendance.push({
        minPercentage: +value,
        activityTypeId: attendanceId,
      });
    }
    setValue('attendance', newAttendance);
  };

  return (
    <>
      <Row className="pt-4">
        <Col xs={12} md={4}>
          <Row>
            <Col xs={12}>
              <SectionTitle text={t(`${prefix}.assistanceRequired`)} />
            </Col>
            {mode !== CourseFormMode.WATCH && (
              <Col xs={12}>
                <p className="fs-16 fw-300">
                  {t(`${prefix}.assistanceRequiredSubtitle`)}
                </p>
              </Col>
            )}
            <Col xs={12}>
              <Table<ActivityType>
                columns={[
                  {
                    columnName: 'name',
                    width: '50%',
                    thClassName: 'text-center',
                    tdClassName: 'text-center',
                    headerText: t(`${prefix}.activityType`),
                  },
                  {
                    columnName: 'id',
                    width: '50%',
                    thClassName: 'text-center',
                    tdClassName: 'text-center',
                    headerText: t(`${prefix}.obligatoryAttendance`),
                    cellFormat: ({ row }) => (
                      <div
                        className="d-flex m-auto align-items-center"
                        style={{ maxWidth: '65px' }}
                      >
                        <Input
                          name={`attendance_${row.name}`}
                          type="text"
                          onChange={(e) => {
                            onChangeAttendance(row.id, e.target.value);
                          }}
                          value={
                            attendance.find(
                              (att) => att.activityTypeId === row.id,
                            )?.minPercentage ?? 0
                          }
                          disabled={mode === CourseFormMode.WATCH}
                        />{' '}
                        <span className="pl-2">%</span>
                      </div>
                    ),
                  },
                ]}
                data={activityTypes}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={8} className="pt-4 pt-md-0">
          <Row>
            <Col
              xs={12}
              className="d-flex justify-content-between align-items-center p-4"
            >
              <SectionTitle text={t(`${prefix}.prerequisites`)} />
              {mode !== CourseFormMode.WATCH && (
                <Button
                  icon="plus"
                  onClick={() => setModalOpen(true)}
                  size="md"
                  text={t(`${prefix}.addPrerequisite`)}
                />
              )}
            </Col>
            <Col xs={12}>
              <Table
                columns={columnsPrerequisites}
                data={prerequisites}
                noResultsText={
                  <TableEmptyContent
                    title={t(`${prefix}.titleEmptyPrerequisites`)}
                    subtitle={t(
                      `${prefix}.${
                        mode === CourseFormMode.WATCH
                          ? 'watchBodyEmptyPrerequisites'
                          : 'bodyEmptyPrerequisites'
                      }`,
                    )}
                  />
                }
              />
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={4} className="pt-4 pt-md-0">
          <Row>
            <Col xs={12} className="pt-4 mt-2">
              <SectionTitle text={t(`${prefix}.syllabusTitle`)} />
            </Col>
            <Col xs={12}>
              <p className="fs-16 fw-300">
                {t(`${prefix}.syllabusDescription`)}
              </p>
            </Col>
            <Col xs={12}>
              <FileInputControlled
                btnText=""
                name="syllabusFile"
                label=""
                accept="application/pdf, application/xls, application/xlsx, application/docx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                control={control}
                onDownloadFile={
                  canDownloadSyllabus ? onDownloadSyllabus : undefined
                }
                shouldUnregister
              />
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={8} className="pt-4 pt-md-0">
          <Row>
            <Col
              xs={12}
              className="d-flex justify-content-between align-items-center p-4"
            >
              <SectionTitle text={t(`${prefix}.compatibilities`)} />
              {mode !== CourseFormMode.WATCH && (
                <Button
                  icon="plus"
                  onClick={() => setModalCompatibilitiesOpen(true)}
                  size="md"
                  text={t(`${prefix}.addCompatibility`)}
                />
              )}
            </Col>
            <Col xs={12}>
              <Table
                columns={columnsCompatibilities}
                data={compatibilities}
                noResultsText={
                  <TableEmptyContent
                    title={t(`${prefix}.titleEmptyCompatibilities`)}
                    subtitle={t(
                      `${prefix}.${
                        mode === CourseFormMode.WATCH
                          ? 'watchBodyEmptyCompatibilities'
                          : 'bodyEmptyCompatibilities'
                      }`,
                    )}
                  />
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalAddPrerequisite
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
      />
      <ModalAddCompatibility
        isOpen={modalCompatibilitiesOpen}
        toggle={() => setModalCompatibilitiesOpen(false)}
      />
    </>
  );
};

export default CourseFormPart2;
