import { Alert, DetailBox, Loading, SelectOptionType } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import GoBackButton from '../../../components/buttons/GoBackButton';
import DisplayError from '../../../components/info/DisplayError';
import { PathsLayouts } from '../../../config/routes';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { formatDate } from '../../../utils/dates';
import { getSelectedOption } from '../../../utils/selectFormat';
import { CoursesFeaturesOptions } from '../../Configurations/academics/interfaces/courses-features.interface';
import {
  getSchoolListRequest,
  requestCourseTypes,
  requestGetCourse,
} from './api';
import CourseForm, {
  CourseFormFields,
  CourseFormMode,
  RetakeExamAllowedCode,
} from './parts/CourseForm';

const CourseUpdate = () => {
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const history = useHistory();
  const [schools, setSchools] = useState<SelectOptionType[]>([]);
  const [academicLevels, setAcademicLevels] = useState<SelectOptionType[]>([]);
  const [modalities, setModalities] = useState<SelectOptionType[]>([]);
  const [coursesFeatures, setCoursesFeatures] =
    useState<CoursesFeaturesOptions>();

  const [courseTypes, setCourseTypes] = useState<
    { id: number; name: string }[]
  >([]);
  const [activityTypes, setActivityTypes] = useState<
    { id: number; name: string }[]
  >([]);
  const [courseDetail, setCourseDetail] = useState<{
    createdAt: string;
    updatedAt: string;
  }>();
  const [syllabusURL, setSyllabusURL] = useState<string>();
  const [defaultValues, setDefaultValues] =
    useState<DefaultValues<CourseFormFields>>();

  const [isDisabledToUpdate, setIsDisabledToUpdate] = useState<boolean>(false);
  const { id: courseId } = useParams<{ id: string }>();
  const location = useLocation();

  const retakeExamOptions = useMemo<SelectOptionType[]>(
    () => [
      {
        label: t(
          `courses.update.retakeExamAllowed_${RetakeExamAllowedCode.Disabled}`,
        ),
        value: RetakeExamAllowedCode.Disabled,
      },
      {
        label: t(
          `courses.update.retakeExamAllowed_${RetakeExamAllowedCode.Enabled}`,
        ),
        value: RetakeExamAllowedCode.Enabled,
      },
    ],
    [t],
  );

  const mode = useMemo(() => {
    if (courseId && location.pathname.includes('watch')) {
      return CourseFormMode.WATCH;
    } else if (courseId && location.pathname.includes('update')) {
      return CourseFormMode.UPDATE;
    } else {
      return CourseFormMode.CREATE;
    }
  }, [courseId, location]);

  const handleDownloadSyllabus = useCallback(() => {
    if (!syllabusURL?.trim()) {
      return;
    }
    window.open(syllabusURL, '_blank');
  }, [syllabusURL]);

  const watchOrUpdateRequest = useCallback(async () => {
    setLoading(true);
    setErrorLoading(undefined);
    const [
      { data: formDataResponse },
      { data: courseTypesResponse },
      { data: courseResponse },
    ] = await Promise.all([
      getSchoolListRequest(),
      requestCourseTypes(),
      requestGetCourse(+courseId),
    ]);

    if (formDataResponse && courseTypesResponse && courseResponse) {
      const { data: course } = courseResponse;
      const arraySchools = formDataResponse.schools.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      const arrayAcademicLevels = formDataResponse.academicLevels.map(
        (item) => ({
          value: item.id,
          label: item.name,
        }),
      );
      const arrayModalities = formDataResponse.modalities.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setSchools(arraySchools);
      setAcademicLevels(arrayAcademicLevels);
      setModalities(arrayModalities);
      setCourseTypes(courseTypesResponse.data);
      setActivityTypes(formDataResponse.activities);
      setCoursesFeatures(formDataResponse?.coursesFeatures ?? undefined);
      setSyllabusURL(course?.syllabus?.url?.trim() ?? undefined);
      setDefaultValues({
        shortening: course.shortening,
        code: course.shortening,
        name: course.name,
        credits: course.credits.toString(),
        school: getSelectedOption(course.schoolId, arraySchools),
        academicLevel: getSelectedOption(
          course.academicLevelId,
          arrayAcademicLevels,
        ),
        syllabusFile: course?.syllabus
          ? new File(
              [] as any,
              `${course?.syllabus?.name}.${course?.syllabus?.extension}`,
              { lastModified: -2 },
            )
          : null,
        modality: getSelectedOption(course.modalityId ?? 0, arrayModalities),
        retakeExamAllowed: getSelectedOption(
          course.retakeExamAllowed
            ? RetakeExamAllowedCode.Enabled
            : RetakeExamAllowedCode.Disabled,
          retakeExamOptions,
        ),
        courseTypes: course.types.map((type) => {
          return type.id;
        }),
        attendance:
          course?.assistanceRequirements?.map((att) => {
            return {
              minPercentage: att.minPercentage,
              activityTypeId: att.activityTypeId,
            };
          }) ?? [],
        prerequisites:
          course?.dependsOn?.map((prerequisite) => {
            return {
              id: prerequisite.id,
              shortening: prerequisite.shortening,
              name: prerequisite.name,
              credits: prerequisite.credits,
            };
          }) ?? [],
        compatibilities:
          course?.compatibilities?.map((compatibility) => {
            return {
              id: compatibility.id,
              shortening: compatibility.shortening,
              name: compatibility.name,
              credits: compatibility.credits,
            };
          }) ?? [],
      });
      setCourseDetail({
        createdAt: course.createdAt,
        updatedAt: course.updatedAt,
      });
      setIsDisabledToUpdate(
        Boolean(course?.semesterCourses?.length) ||
          Boolean(course?.sections?.length),
      );
    } else {
      setErrorLoading('ERROR');
    }
    setLoading(false);
  }, [courseId, retakeExamOptions, setLoading, setErrorLoading]);

  const createRequest = useCallback(async () => {
    setLoading(true);
    setErrorLoading(undefined);
    const [{ data: formDataResponse }, { data: courseTypesResponse }] =
      await Promise.all([getSchoolListRequest(), requestCourseTypes()]);

    if (formDataResponse && courseTypesResponse) {
      const arrayAcademicLevels = formDataResponse.academicLevels.map(
        (item) => ({
          value: item.id,
          label: item.name,
        }),
      );
      const arraySchools = formDataResponse.schools.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      const arrayModalities = formDataResponse.modalities.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSchools(arraySchools);
      setAcademicLevels(arrayAcademicLevels);
      setModalities(arrayModalities);
      setCourseTypes(courseTypesResponse.data);
      setActivityTypes(formDataResponse.activities);
      setCoursesFeatures(formDataResponse?.coursesFeatures ?? undefined);
    } else {
      setErrorLoading('ERROR');
    }
    setLoading(false);
  }, [setErrorLoading, setLoading]);

  useEffect(() => {
    if (mode !== CourseFormMode.CREATE) {
      watchOrUpdateRequest();
    } else {
      createRequest();
    }
  }, [mode, createRequest, watchOrUpdateRequest]);

  if (loading && !errorLoading) {
    return (
      <div className="mx-3">
        <Loading insideCard textBody={''} />
      </div>
    );
  }
  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => setLoading(true)}
          loadingAction={loading}
        />
      </div>
    );
  }
  return (
    <Card className="p-4 mt-2 mx-3">
      <Row>
        <Col xs={12} lg={8}>
          <GoBackButton
            onClick={() =>
              history.push(`${PathsLayouts.academicOffers}/curricular-subjects`)
            }
            text={t(`common.actions.goBackStep`)}
          />
        </Col>
        {mode !== CourseFormMode.CREATE && (
          <Col xs={12} lg={4}>
            <DetailBox
              columns={[
                {
                  title: t('common.createdAt'),
                  body: courseDetail
                    ? formatDate(courseDetail.createdAt, 'DD-MM-YYYY')
                    : '',
                  size: { xs: 12, md: 6 },
                },
                {
                  title: t('common.updatedAt'),
                  body: courseDetail
                    ? formatDate(courseDetail.updatedAt, 'DD-MM-YYYY')
                    : '',
                  size: { xs: 12, md: 6 },
                },
              ]}
            />
          </Col>
        )}
        {isDisabledToUpdate && (
          <Col xs={12}>
            <Alert
              className="mt-3 mb-2"
              color="info"
              icon="information"
              size="lg"
              text={t(`courses.update.infoEditMessage`)}
            />
          </Col>
        )}
      </Row>
      <CourseForm
        mode={mode}
        defaultValues={defaultValues ?? {}}
        schools={schools}
        courseTypes={courseTypes}
        activityTypes={activityTypes}
        academicLevels={academicLevels}
        retakeExamOptions={retakeExamOptions}
        isDisabled={isDisabledToUpdate}
        modalities={modalities}
        coursesFeatures={coursesFeatures}
        onDownloadSyllabus={handleDownloadSyllabus}
      />
    </Card>
  );
};

export default CourseUpdate;
