import { Button, Modal, TextInput } from '@octano/global-ui';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import RemoteSearchSelect from '../../../../components/form/RemoteSearchSelect';
import { useSearchCourse } from '../../../../hooks/remoteSearchSelect/useSearchCourse';
import { Compatibility } from '../../../../types/courseMaintainerTypes';
import { CourseFormFields } from './CourseForm';

type ModalAddCompatibilityProps = {
  isOpen: boolean;
  toggle: () => void;
};

const ModalAddCompatibility = ({
  isOpen,
  toggle,
}: ModalAddCompatibilityProps) => {
  const prefix = 'courses.update';
  const { t } = useTranslation();
  const searchCourse = useSearchCourse();
  const { setValue, getValues } = useFormContext<CourseFormFields>();
  const [compatibility, setCompatibility] = useState<Compatibility>();
  const [error, setError] = useState<string>();

  const addCompatibility = () => {
    if (compatibility) {
      const newCompatibilities = getValues('compatibilities') || [];

      if (newCompatibilities.find((course) => course.id === compatibility.id)) {
        setError(t(`${prefix}.compatibilityErrorSelected`));
        return;
      }

      newCompatibilities.push(compatibility);
      setValue('compatibilities', newCompatibilities);
      onClose();
    } else {
      setError(t(`${prefix}.compatibilityErrorRequired`));
    }
  };

  const onClose = () => {
    setCompatibility(undefined);
    setError(undefined);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" unmountOnClose>
      <Row>
        <Col xs={12}>
          <p className="fs-22 text-dark text-center mb-4">
            {t(`${prefix}.modalCompatibilityTitle`)}
          </p>
        </Col>
        <Col xs={12} className="pb-2">
          <RemoteSearchSelect
            name="courseCompatibility"
            label={t(`${prefix}.modalCompatibilityName`)}
            filterOptions={searchCourse}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setCompatibility({
                  id: selectedOption.id,
                  name: selectedOption.name,
                  shortening: selectedOption.shortening,
                  credits: selectedOption.credits,
                });
                setError(undefined);
              } else {
                setCompatibility(undefined);
              }
            }}
            errorText={error}
          />
        </Col>
        <Col xs={6}>
          <TextInput
            name="shorteningCompatibility"
            label={t(`${prefix}.modalCompatibilityShortening`)}
            value={compatibility?.shortening}
            disabled
          />
        </Col>
        <Col xs={6}>
          <TextInput
            name="creditsCompatibility"
            label={t(`${prefix}.modalCompatibilityCredits`)}
            value={compatibility?.credits}
            disabled
          />
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} lg={6} className="pb-2 order-2 order-lg-1">
          <Button
            type="button"
            outlined
            onClick={onClose}
            text={t(`common.actions.cancel`)}
            fullwidth
          />
        </Col>
        <Col xs={12} lg={6} className="pb-2 order-1 order-lg-2">
          <Button
            text={t(`${prefix}.modalCompatibilitySave`)}
            onClick={addCompatibility}
            fullwidth
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalAddCompatibility;
